<template>
  <!-- <a-config-provider :locale="locale">
    <div class="page-app">
      <header class="page-app__header">
        <router-link class="page-app__title" to="/">Onlyoffice</router-link>
        <span>{{ $route.meta.title }}</span>
      </header>
      <div class="page-app__body">
        <router-view />
      </div>
    </div>
  </a-config-provider> -->
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import locale from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
  data() {
    return {
      locale
    }
  }
}
</script>

<style>
html,
body {
  height: 100%;
}
#app {
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 0;
}
</style>
<style lang="less">
// body {
//   padding: 0;
//   margin: 0;
// }
// html,
// body {
//   font-size: 14px;
//   color: #333;
//   font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial,
//     sans-serif;
// }
// .page-app {
//   &__header {
//     padding: 16px 32px;
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   }
//   &__title {
//     font-size: 24px;
//     margin-right: 16px;
//     color: #333;
//   }
//   &__body {
//     padding: 20px 32px;
//   }
// }
</style>
